import React, { useEffect, useState } from 'react';
import { fetchAllplansConfiguration } from '../_redux/planCrud';
import ErrorCard from '../../../common/ErrorCard';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import { useHistory, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

function AllPlans() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planTobeChanged, setplanTobeChanged] = useState([]);
  const [planIdsInvestable, setplanIdsInvestable] = useState([]);
  const [planIdsDiscountable, setPlanIdsDiscountable] = useState([]);
  const [planIdsViewable, setplanIdsViewable] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const getPlans = async () => {
    try {
      setLoading(true);
      const res = await fetchAllplansConfiguration('false');
      setPlans(res.data.data);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <section>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <h4 className="pt-8"> All Funds</h4>
            <div
              style={{
                height: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <button
                className="btn btn-light-primary font-weight-bold"
                type="button"
                onClick={() => history.push('plan-configuration')}
              >
                Fund Configuration
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
          <div className="card-body mt-3 mb-15 pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-uppercase text-center">
                      <th style={{ minWidth: '150px' }} className="pl-7">
                        <span className="text-dark-75">Fund ID</span>
                      </th>

                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Name</span>
                      </th>

                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Cool Name</span>
                      </th>

                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Identifier</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Sector</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Currency</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Level</span>
                      </th>

                      {grantPermission(
                        AdminPermissions.PAY_FUND_DIVIDENDS_OR_INTEREST,
                      ) ? (
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">
                            DIVIDEND/INTEREST
                          </span>
                        </th>
                      ) : null}

                      {grantPermission(
                        AdminPermissions.MANIPULATE_CORPORATE_ACTIONS,
                      ) ? (
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">CORPRATE ACTION</span>
                        </th>
                      ) : null}
                      {grantPermission(AdminPermissions.USER_VIEW) ? (
                        <>
                          <th style={{ minWidth: '200px' }}>
                            <span className="text-dark-75">View Investors</span>
                          </th>
                        </>
                      ) : (
                        ''
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {plans &&
                      plans.map(
                        eachPlan =>
                          eachPlan?.plans &&
                          eachPlan?.plans.map((onePlan, index) => (
                            <tr key={index}>
                              <td className="pl-0 py-8 text-center">
                                <span className="text-color text-hover-primary mb-1 font-size-lg">
                                  {onePlan?.id ? onePlan?.id : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.name ? onePlan?.name : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.coolName
                                    ? onePlan?.coolName
                                    : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.identifier
                                    ? onePlan?.identifier
                                    : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.sector ? onePlan?.sector : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.currency
                                    ? onePlan?.currency
                                    : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {onePlan?.planLevel
                                    ? onePlan?.planLevel
                                    : '---'}
                                </span>
                              </td>

                              {grantPermission(
                                AdminPermissions.PAY_FUND_DIVIDENDS_OR_INTEREST,
                              ) ? (
                                <td className="pr-0 text-center">
                                  <button
                                    className="btn btn-light-primary font-weight-bold"
                                    onClick={() =>
                                      history.push(`/plan/${onePlan?.id}`)
                                    }
                                  >
                                    Pay
                                  </button>
                                </td>
                              ) : null}

                              {grantPermission(
                                AdminPermissions.MANIPULATE_CORPORATE_ACTIONS,
                              ) ? (
                                <td className="pr-0 text-center">
                                  <button
                                    className="btn btn-light-primary font-weight-bold"
                                    onClick={() =>
                                      history.push(
                                        `/corporate-action/plan/${onePlan?.id}`,
                                      )
                                    }
                                  >
                                    Open
                                  </button>
                                </td>
                              ) : null}

                              {grantPermission(AdminPermissions.USER_VIEW) ? (
                                <td className="pr-0 text-center">
                                  <Link
                                    to={`/fund/${onePlan?.id}/users`}
                                    className="btn btn-light-primary  font-size-sm"
                                    style={{
                                      minWidth: '150px',
                                    }}
                                  >
                                    View Investors
                                  </Link>
                                </td>
                              ) : null}
                            </tr>
                          )),
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default AllPlans;
