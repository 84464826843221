import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const INVESTORS_URL = `${REACT_APP_BASE_URL}/v1/admin/investors`;
const FETCH_INVESTOR_HISTORY = `${REACT_APP_BASE_URL}/v1/kyc-audit/`;

const fetchInvestors = async (page, limit) => {
  let res = '';
  if (page && limit) {
    res = await axios.get(`${INVESTORS_URL}?page=${page}&limit=${limit}`);
  } else {
    res = await axios.get(`${INVESTORS_URL}`);
  }
  return res;
};
const searchInvestors = async (
  kycStatus,
  userLevel,
  loadInvestorsWithPendingTransactions,
  dateColumnName,
  startDate,
  endDate,
  search,
  page,
  limit,
) => {
  let URL = INVESTORS_URL + '?';
  let queryParams = [
    'kycStatus',
    'userLevel',
    'loadInvestorsWithPendingTransactions',
    'dateColumnName',
    'startDate',
    'endDate',
    'search',
    'page',
    'limit',
  ];
  let functionParams = [
    kycStatus,
    userLevel,
    loadInvestorsWithPendingTransactions,
    dateColumnName,
    startDate,
    endDate,
    search,
    page,
    limit,
  ];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }

  const res = await axios.get(URL);
  return res;
};

const fetchInvestorHistory = async investorId => {
  const res = await axios.get(`${FETCH_INVESTOR_HISTORY}${investorId}`);
  return res;
};

export { fetchInvestors, searchInvestors, fetchInvestorHistory };
